<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="notification" />

    <v-row class="ml-2 mr-2">
      <v-card
              width="1580"
              height="70"
              style="border-radius: 16px; display: flex; justify-content: flex-start; align-items: center;"
              class="ml-2 mt-2"
            >
              <div>
                <p style="font-size: 20px; color: #424242; text-align: left;" class="mt-0 ml-5">
                  Choose the language for sending emails.
                </p>
              </div>
              <div class="ml-2 mt-1">
                <v-radio-group v-model="selectedUserRadio">
                  <v-row class="ma-0 pa-0" no-gutters>
                    <v-col cols="auto" class="d-flex align-center">
                    
                      <div style="display: flex; align-items: center;">
                        <v-radio value="thai" class="mr-2 mt-2 ml-0"></v-radio>
                        <v-img
                          src="@/assets/thailandflag.svg"
                          width="35"
                          height="22"
                          contain
                        ></v-img>
                      </div>
                    </v-col>

                    <v-col cols="auto" class="d-flex align-center ml-2">
                    
                      <div style="display: flex; align-items: center;">
                        <v-radio value="eng" class="mr-2 mt-2"></v-radio>
                        <v-img
                          src="@/assets/ukflag.svg"
                          width="35"
                          height="22"
                          contain
                        ></v-img>
                      </div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
            </v-card>
       </v-row>
    <v-container fluid class="mt-3">
      <!-- New UI -->
       
      <v-row >
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-icon style="font-size: 30px">mdi-file-document</v-icon>
              <p class="title_style">{{ $t("libraryArticle") }}</p>
              <v-spacer></v-spacer>
              <div class="onoffswitch mt-3 ml-5">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch1"
                  :disabled="disable"
                  @input="Show_correctAns_switch(carddisable)"
                  v-model="carddisable"
                />
                <label class="onoffswitch-label" for="myonoffswitch1">
                  <div class="onoffswitch-inner"></div>
                  <div class="onoffswitch-switch"></div>
                </label>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && carddisable == true ? false : true
                    "
                    color="#a6cc39"
                    v-model="article_endDate.beforeEnd"
                    @click="checkarticle_endDate(article_endDate.beforeEnd)"
                  >
                    <template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeEndDate") }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model.number="article_endDate.beforeEndDate"
                    dense
                    :disabled="
                      disable == false && carddisable == true ? false : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="0"
                    @keypress="isNumber($event)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="article_endDate.inbox"
                    :label="$t('inbox')"
                    color="#a6cc39"
                    :disabled="
                      article_endDate.inboxdisable == false &&
                      carddisable == true
                        ? false
                        : true
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="article_endDate.pushnoti"
                    color="#a6cc39"
                    class="chk-input"
                    :disabled="
                      article_endDate.pushnotidisable == false &&
                      carddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="article_endDate.email"
                    color="#a6cc39"
                    :disabled="
                      article_endDate.emaildisable == false &&
                      carddisable == true
                        ? false
                        : true
                    "
                    :label="$t('email')"
                    class="chk-input"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && carddisable == true ? false : true
                    "
                    color="#a6cc39"
                    v-model="article_expDate.beforeExp"
                    @click="checkarticle_expireDate(article_expDate.beforeExp)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeExpDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="article_expDate.beforeExpDate"
                    dense
                    :disabled="
                      disable == false && carddisable == true ? false : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="article_expDate.inbox"
                    :label="$t('inbox')"
                    color="#a6cc39"
                    :disabled="
                      article_expDate.inboxdisable == false &&
                      carddisable == true
                        ? false
                        : true
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="article_expDate.pushnoti"
                    color="#a6cc39"
                    :disabled="
                      article_expDate.pushnotidisable == false &&
                      carddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="article_expDate.email"
                    class="chk-input"
                    color="#a6cc39"
                    :disabled="
                      article_expDate.emaildisable == false &&
                      carddisable == true
                        ? false
                        : true
                    "
                    :label="$t('email')"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-icon style="font-size: 30px">mdi-volume-high</v-icon>
              <p class="title_style">{{ $t("librarySound") }}</p>

              <v-spacer></v-spacer>
              <div class="onoffswitch mt-3 ml-5">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch2"
                  :disabled="disable"
                  @input="Show_Sound_switch(soundcarddisable)"
                  v-model="soundcarddisable"
                />
                <label class="onoffswitch-label" for="myonoffswitch2">
                  <div class="onoffswitch-inner"></div>
                  <div class="onoffswitch-switch"></div>
                </label>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && soundcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    v-model="sound_endDate.beforeEnd"
                    @click="checksound_endDate(sound_endDate.beforeEnd)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeEndDate") }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="sound_endDate.beforeEndDate"
                    dense
                    :disabled="
                      disable == false && soundcarddisable == true
                        ? false
                        : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="0"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="sound_endDate.inbox"
                    :label="$t('inbox')"
                    :disabled="
                      sound_endDate.inboxdisable == false &&
                      soundcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="sound_endDate.pushnoti"
                    color="#a6cc39"
                    class="chk-input"
                    :disabled="
                      sound_endDate.pushnotidisable == false &&
                      soundcarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="sound_endDate.email"
                    color="#a6cc39"
                    :label="$t('email')"
                    :disabled="
                      sound_endDate.emaildisable == false &&
                      soundcarddisable == true
                        ? false
                        : true
                    "
                    class="chk-input"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && soundcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    v-model="sound_expDate.beforeExp"
                    @click="checksound_expireDate(sound_expDate.beforeExp)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeExpDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="sound_expDate.beforeExpDate"
                    dense
                    :disabled="
                      disable == false && soundcarddisable == true
                        ? false
                        : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="sound_expDate.inbox"
                    :label="$t('inbox')"
                    :disabled="
                      sound_expDate.inboxdisable == false &&
                      soundcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="sound_expDate.pushnoti"
                    color="#a6cc39"
                    :disabled="
                      sound_expDate.pushnotidisable == false &&
                      soundcarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="sound_expDate.email"
                    class="chk-input"
                    color="#a6cc39"
                    :disabled="
                      sound_expDate.emaildisable == false &&
                      soundcarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('email')"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-img
                src="@/assets/images/video_img_black.svg"
                max-width="28"
                height="28"
                class="mr-2"
              ></v-img>
              <p class="title_style">{{ $t("library-video") }}</p>

              <v-spacer></v-spacer>
              <div class="onoffswitch mt-3 ml-5">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch3"
                  :disabled="disable"
                  @input="Show_Video_switch(videocarddisable)"
                  v-model="videocarddisable"
                />
                <label class="onoffswitch-label" for="myonoffswitch3">
                  <div class="onoffswitch-inner"></div>
                  <div class="onoffswitch-switch"></div>
                </label>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && videocarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    v-model="video_endDate.beforeEnd"
                    @click="checkvideo_endDate(video_endDate.beforeEnd)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeEndDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="video_endDate.beforeEndDate"
                    dense
                    :disabled="
                      disable == false && videocarddisable == true
                        ? false
                        : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="0"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="video_endDate.inbox"
                    :label="$t('inbox')"
                    color="#a6cc39"
                    :disabled="
                      video_endDate.inboxdisable == false &&
                      videocarddisable == true
                        ? false
                        : true
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="video_endDate.pushnoti"
                    color="#a6cc39"
                    class="chk-input"
                    :disabled="
                      video_endDate.pushnotidisable == false &&
                      videocarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="video_endDate.email"
                    color="#a6cc39"
                    :disabled="
                      video_endDate.emaildisable == false &&
                      videocarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('email')"
                    class="chk-input"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && videocarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    v-model="video_expDate.beforeExp"
                    @click="checkvideo_expireDate(video_expDate.beforeExp)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeExpDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="video_expDate.beforeExpDate"
                    dense
                    :disabled="
                      disable == false && videocarddisable == true
                        ? false
                        : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="video_expDate.inbox"
                    :label="$t('inbox')"
                    color="#a6cc39"
                    :disabled="
                      video_expDate.inboxdisable == false &&
                      videocarddisable == true
                        ? false
                        : true
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    :disabled="
                      video_expDate.pushnotidisable == false &&
                      videocarddisable == true
                        ? false
                        : true
                    "
                    v-model="video_expDate.pushnoti"
                    color="#a6cc39"
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="video_expDate.email"
                    class="chk-input"
                    color="#a6cc39"
                    :disabled="
                      video_expDate.emaildisable == false &&
                      videocarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('email')"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-img
                src="@/assets/images/book_img.svg"
                max-width="23"
                height="23"
                class="mr-3"
              ></v-img>
              <p class="title_style">{{ $t("lessonlearn") }}</p>
              <v-spacer></v-spacer>
              <div class="onoffswitch mt-3 ml-5">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch4"
                  :disabled="disable"
                  @input="Show_Lesson_switch(llcarddisable)"
                  v-model="llcarddisable"
                />
                <label class="onoffswitch-label" for="myonoffswitch4">
                  <div class="onoffswitch-inner"></div>
                  <div class="onoffswitch-switch"></div>
                </label>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && llcarddisable == true ? false : true
                    "
                    color="#a6cc39"
                    v-model="lessonlearn_endDate.beforeEnd"
                    @click="
                      checklessonlearn_endDate(lessonlearn_endDate.beforeEnd)
                    "
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeEndDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="lessonlearn_endDate.beforeEndDate"
                    dense
                    :disabled="
                      disable == false && llcarddisable == true ? false : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="0"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="lessonlearn_endDate.inbox"
                    :label="$t('inbox')"
                    color="#a6cc39"
                    :disabled="
                      lessonlearn_endDate.inboxdisable == false &&
                      llcarddisable == true
                        ? false
                        : true
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="lessonlearn_endDate.pushnoti"
                    color="#a6cc39"
                    class="chk-input"
                    :disabled="
                      lessonlearn_endDate.pushnotidisable == false &&
                      llcarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="lessonlearn_endDate.email"
                    color="#a6cc39"
                    :label="$t('email')"
                    :disabled="
                      lessonlearn_endDate.emaildisable == false &&
                      llcarddisable == true
                        ? false
                        : true
                    "
                    class="chk-input"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && llcarddisable == true ? false : true
                    "
                    color="#a6cc39"
                    v-model="lessonlearn_expDate.beforeExp"
                    @click="
                      checklessonlearn_expireDate(lessonlearn_expDate.beforeExp)
                    "
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeExpDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="lessonlearn_expDate.beforeExpDate"
                    dense
                    :disabled="
                      disable == false && llcarddisable == true ? false : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="lessonlearn_expDate.inbox"
                    :label="$t('inbox')"
                    :disabled="
                      lessonlearn_expDate.inboxdisable == false &&
                      llcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="lessonlearn_expDate.pushnoti"
                    color="#a6cc39"
                    :disabled="
                      lessonlearn_expDate.pushnotidisable == false &&
                      llcarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="lessonlearn_expDate.email"
                    class="chk-input"
                    :disabled="
                      lessonlearn_expDate.emaildisable == false &&
                      llcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    :label="$t('email')"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-icon style="font-size: 30px" class="mr-2"
                >mdi-pencil-minus</v-icon
              >
              <p class="title_style">{{ $t("assignmentcourse") }}</p>
              <v-spacer></v-spacer>
              <div class="onoffswitch mt-3 ml-5">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch5"
                  :disabled="disable"
                  @input="Show_Assignment_switch(accarddisable)"
                  v-model="accarddisable"
                />
                <label class="onoffswitch-label" for="myonoffswitch5">
                  <div class="onoffswitch-inner"></div>
                  <div class="onoffswitch-switch"></div>
                </label>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && accarddisable == true ? false : true
                    "
                    color="#a6cc39"
                    v-model="AC_endDate.beforeEnd"
                    @click="checkac_endDate(AC_endDate.beforeEnd)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeEndDate") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="AC_endDate.beforeEndDate"
                    dense
                    :disabled="
                      disable == false && accarddisable == true ? false : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="0"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="AC_endDate.inbox"
                    :label="$t('inbox')"
                    :disabled="
                      AC_endDate.inboxdisable == false && accarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="AC_endDate.pushnoti"
                    color="#a6cc39"
                    :disabled="
                      AC_endDate.pushnotidisable == false &&
                      accarddisable == true
                        ? false
                        : true
                    "
                    class="chk-input"
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="AC_endDate.email"
                    color="#a6cc39"
                    :disabled="
                      AC_endDate.emaildisable == false && accarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('email')"
                    class="chk-input"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-icon style="font-size: 30px" class="mr-2"
                >mdi-calendar-month</v-icon
              >
              <p class="title_style">{{ $t("event") }}</p>
              <v-spacer></v-spacer>
              <div class="onoffswitch mt-3 ml-5">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch6"
                  :disabled="disable"
                  @input="Show_Event_switch(eventcarddisable)"
                  v-model="eventcarddisable"
                />
                <label class="onoffswitch-label" for="myonoffswitch6">
                  <div class="onoffswitch-inner"></div>
                  <div class="onoffswitch-switch"></div>
                </label>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    value
                    :disabled="
                      disable == false && eventcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    v-model="event_endDate.beforeEnd"
                    @click="checkevent_endDate(event_endDate.beforeEnd)"
                    ><template v-slot:label>
                      <div class="subtitle">
                        {{ $t("beforeevent") }}
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="event_endDate.beforeEndDate"
                    dense
                    :disabled="
                      disable == false && eventcarddisable == true
                        ? false
                        : true
                    "
                    class="input-field"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="0"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide">
                <v-col>
                  <v-checkbox
                    class="chk-input"
                    v-model="event_endDate.inbox"
                    :label="$t('inbox')"
                    :disabled="
                      event_endDate.inboxdisable == false &&
                      eventcarddisable == true
                        ? false
                        : true
                    "
                    color="#a6cc39"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="event_endDate.pushnoti"
                    color="#a6cc39"
                    class="chk-input"
                    :disabled="
                      event_endDate.pushnotidisable == false &&
                      eventcarddisable == true
                        ? false
                        : true
                    "
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="event_endDate.email"
                    color="#a6cc39"
                    :label="$t('email')"
                    :disabled="
                      event_endDate.emaildisable == false &&
                      eventcarddisable == true
                        ? false
                        : true
                    "
                    class="chk-input"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- end of New UI -->

      <v-row class="ma-8 d-flex justify-end" v-if="disable">
        <v-btn
          width="110"
          @click="Edit()"
          class="text-capitalize btnStyle"
          color="secondary"
          ><v-icon class="mr-2">mdi-square-edit-outline</v-icon
          >{{ $t("edit") }}</v-btn
        >
      </v-row>
      <v-row v-else class="ma-8 d-flex justify-end">
        <v-btn
          outlined
          class="mr-3"
          text
          @click="Cancel()"
          style="font-size: 18px; background: #ffffff"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          width="110"
          @click="(disable = true), CreateOrUpdateSettingNotification()"
          class="text-capitalize btnStyle"
          color="secondary"
          ><v-icon class="mr-2">mdi-content-save-outline</v-icon
          >{{ $t("save") }}</v-btn
        >
      </v-row>
    </v-container>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    selectedUserRadio: "thai",
    permissionDialog: false,
    permissionMessage: "",
    carddisable: false,
    soundcarddisable: false,
    videocarddisable: false,
    llcarddisable: false,
    accarddisable: false,
    eventcarddisable: false,
    showCorrectAnswer: false,

    lock: false,
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "notification",
      },
    ],
    disable: true,
    article_endDate: {
      beforeEnd: false,
      beforeEndDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    article_expDate: {
      beforeExp: false,
      beforeExpDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    sound_endDate: {
      beforeEnd: false,
      beforeEndDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    sound_expDate: {
      beforeExp: false,
      beforeExpDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    video_endDate: {
      beforeEnd: false,
      beforeEndDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    video_expDate: {
      beforeExp: false,
      beforeExpDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    lessonlearn_endDate: {
      beforeEnd: false,
      beforeEndDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    lessonlearn_expDate: {
      beforeExp: false,
      beforeExpDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    AC_endDate: {
      beforeEnd: false,
      beforeEndDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    event_endDate: {
      beforeEnd: false,
      beforeEndDate: null,
      inbox: false,
      pushnoti: false,
      email: false,
      inboxdisable: true,
      pushnotidisable: true,
      emaildisable: true,
    },
    CreateDate: "",
    CreateBy: "",
  }),
  mounted() {
    this.GetSettingNotification();
  },
  methods: {
    Show_correctAns_switch(v) {
      if (v) {
        this.article_endDate.inboxdisable = false;
      }
    },
    Show_Sound_switch(v) {
      if (v) {
        this.sound_endDate.inboxdisable = false;
      }
    },
    Show_Video_switch(v) {
      if (v) {
        this.video_endDate.inboxdisable = false;
      }
    },
    Show_Lesson_switch(v) {
      if (v) {
        this.lessonlearn_endDate.inboxdisable = false;
      }
    },
    Show_Assignment_switch(v) {
      if (v) {
        this.AC_endDate.inboxdisable = false;
      }
    },
    Show_Event_switch(v) {
      if (v) {
        this.event_endDate.inboxdisable = false;
      }
    },
    Cancel() {
      let that = this;
      that.disable = true;
      that.lock = false;

      that.article_endDate.inboxdisable = true;
      that.article_endDate.pushnotidisable = true;
      that.article_endDate.emaildisable = true;

      that.article_expDate.inboxdisable = true;
      that.article_expDate.pushnotidisable = true;
      that.article_expDate.emaildisable = true;

      that.sound_endDate.inboxdisable = true;
      that.sound_endDate.pushnotidisable = true;
      that.sound_endDate.emaildisable = true;

      that.sound_expDate.inboxdisable = true;
      that.sound_expDate.pushnotidisable = true;
      that.sound_expDate.emaildisable = true;

      that.video_endDate.inboxdisable = true;
      that.video_endDate.pushnotidisable = true;
      that.video_endDate.emaildisable = true;

      that.video_expDate.inboxdisable = true;
      that.video_expDate.pushnotidisable = true;
      that.video_expDate.emaildisable = true;

      that.lessonlearn_endDate.inboxdisable = true;
      that.lessonlearn_endDate.pushnotidisable = true;
      that.lessonlearn_endDate.emaildisable = true;

      that.lessonlearn_expDate.inboxdisable = true;
      that.lessonlearn_expDate.pushnotidisable = true;
      that.lessonlearn_expDate.emaildisable = true;

      that.AC_endDate.inboxdisable = true;
      that.AC_endDate.pushnotidisable = true;
      that.AC_endDate.emaildisable = true;

      that.event_endDate.inboxdisable = true;
      that.event_endDate.pushnotidisable = true;
      that.event_endDate.emaildisable = true;
    },
    Edit() {
      let that = this;

      let checkPermission = that.check_Permissions("notification");
      if (checkPermission === false) {
        that.permissionDialog = true;
        that.permissionMessage =
          "You don't have permission to edit Setting-Notification.";
        return false;
      }

      that.disable = false;
      that.checkarticle_endDate(that.article_endDate.beforeEnd);
      that.checkarticle_expireDate(that.article_expDate.beforeExp);
      that.checksound_endDate(that.sound_endDate.beforeEnd);
      that.checksound_expireDate(that.sound_expDate.beforeExp);
      that.checkvideo_endDate(that.video_endDate.beforeEnd);
      that.checkvideo_expireDate(that.video_expDate.beforeExp);
      that.checklessonlearn_endDate(that.lessonlearn_endDate.beforeEnd);
      that.checklessonlearn_expireDate(that.lessonlearn_expDate.beforeExp);
      that.checkac_endDate(that.AC_endDate.beforeEnd);
      that.checkevent_endDate(that.event_endDate.beforeEnd);
    },
    checkarticle_endDate(item) {
      let that = this;
      if (item) {
        that.article_endDate.inboxdisable = false;
        that.article_endDate.pushnotidisable = false;
        that.article_endDate.emaildisable = false;
      } else {
        that.article_endDate.inboxdisable = true;
        that.article_endDate.pushnotidisable = true;
        that.article_endDate.emaildisable = true;
        that.article_endDate.inbox = false;
        that.article_endDate.pushnoti = false;
        that.article_endDate.email = false;
      }
    },
    checkarticle_expireDate(item) {
      let that = this;
      if (item) {
        that.article_expDate.inboxdisable = false;
        that.article_expDate.pushnotidisable = false;
        that.article_expDate.emaildisable = false;
      } else {
        that.article_expDate.inboxdisable = true;
        that.article_expDate.pushnotidisable = true;
        that.article_expDate.emaildisable = true;
        that.article_expDate.inbox = false;
        that.article_expDate.pushnoti = false;
        that.article_expDate.email = false;
      }
    },
    checksound_endDate(item) {
      let that = this;
      if (item) {
        that.sound_endDate.inboxdisable = false;
        that.sound_endDate.pushnotidisable = false;
        that.sound_endDate.emaildisable = false;
      } else {
        that.sound_endDate.inboxdisable = true;
        that.sound_endDate.pushnotidisable = true;
        that.sound_endDate.emaildisable = true;
        that.sound_endDate.inbox = false;
        that.sound_endDate.pushnoti = false;
        that.sound_endDate.email = false;
      }
    },
    checksound_expireDate(item) {
      let that = this;
      if (item) {
        that.sound_expDate.inboxdisable = false;
        that.sound_expDate.pushnotidisable = false;
        that.sound_expDate.emaildisable = false;
      } else {
        that.sound_expDate.inboxdisable = true;
        that.sound_expDate.pushnotidisable = true;
        that.sound_expDate.emaildisable = true;
        that.sound_expDate.inbox = false;
        that.sound_expDate.pushnoti = false;
        that.sound_expDate.email = false;
      }
    },
    checkvideo_endDate(item) {
      let that = this;
      if (item) {
        that.video_endDate.inboxdisable = false;
        that.video_endDate.pushnotidisable = false;
        that.video_endDate.emaildisable = false;
      } else {
        that.video_endDate.inboxdisable = true;
        that.video_endDate.pushnotidisable = true;
        that.video_endDate.emaildisable = true;
        that.video_endDate.inbox = false;
        that.video_endDate.pushnoti = false;
        that.video_endDate.email = false;
      }
    },
    checkvideo_expireDate(item) {
      let that = this;
      if (item) {
        that.video_expDate.inboxdisable = false;
        that.video_expDate.pushnotidisable = false;
        that.video_expDate.emaildisable = false;
      } else {
        that.video_expDate.inboxdisable = true;
        that.video_expDate.pushnotidisable = true;
        that.video_expDate.emaildisable = true;
        that.video_expDate.inbox = false;
        that.video_expDate.pushnoti = false;
        that.video_expDate.email = false;
      }
    },
    checklessonlearn_endDate(item) {
      let that = this;
      if (item) {
        that.lessonlearn_endDate.inboxdisable = false;
        that.lessonlearn_endDate.pushnotidisable = false;
        that.lessonlearn_endDate.emaildisable = false;
      } else {
        that.lessonlearn_endDate.inboxdisable = true;
        that.lessonlearn_endDate.pushnotidisable = true;
        that.lessonlearn_endDate.emaildisable = true;
        that.lessonlearn_endDate.inbox = false;
        that.lessonlearn_endDate.pushnoti = false;
        that.lessonlearn_endDate.email = false;
      }
    },
    checklessonlearn_expireDate(item) {
      let that = this;
      if (item) {
        that.lessonlearn_expDate.inboxdisable = false;
        that.lessonlearn_expDate.pushnotidisable = false;
        that.lessonlearn_expDate.emaildisable = false;
      } else {
        that.lessonlearn_expDate.inboxdisable = true;
        that.lessonlearn_expDate.pushnotidisable = true;
        that.lessonlearn_expDate.emaildisable = true;
        that.lessonlearn_expDate.inbox = false;
        that.lessonlearn_expDate.pushnoti = false;
        that.lessonlearn_expDate.email = false;
      }
    },
    checkac_endDate(item) {
      let that = this;
      if (item) {
        that.AC_endDate.inboxdisable = false;
        that.AC_endDate.pushnotidisable = false;
        that.AC_endDate.emaildisable = false;
      } else {
        that.AC_endDate.inboxdisable = true;
        that.AC_endDate.pushnotidisable = true;
        that.AC_endDate.emaildisable = true;
        that.AC_endDate.inbox = false;
        that.AC_endDate.pushnoti = false;
        that.AC_endDate.email = false;
      }
    },
    checkevent_endDate(item) {
      let that = this;
      if (item) {
        that.event_endDate.inboxdisable = false;
        that.event_endDate.pushnotidisable = false;
        that.event_endDate.emaildisable = false;
      } else {
        that.event_endDate.inboxdisable = true;
        that.event_endDate.pushnotidisable = true;
        that.event_endDate.emaildisable = true;
        that.event_endDate.inbox = false;
        that.event_endDate.pushnoti = false;
        that.event_endDate.email = false;
      }
    },
    isNumber(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    async GetSettingNotification() {
      let that = this;
      await axios
        .get(
          `${that.web_url}SendNotification/GetDataSettingNotification?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            console.log("res.data.data",response.data.data);
            console.log("res.data.data",response.data.data.emailLanguage);
            if (response.data.data != null) {
              that.selectedUserRadio = response.data.data.emailLanguage;
              that.carddisable = response.data.data.articleFlag;
              that.article_endDate.beforeEnd =
                response.data.data.articleBEndFlag;
              that.article_endDate.beforeEndDate =
                response.data.data.articleBEndDay;
              that.article_endDate.inbox = response.data.data.articleBEndInbox;
              that.article_endDate.pushnoti =
                response.data.data.articleBEndPushNoti;
              that.article_endDate.email = response.data.data.articleBEndEmail;

              that.article_expDate.beforeExp =
                response.data.data.articleBExpFlag;
              that.article_expDate.beforeExpDate =
                response.data.data.articleBExpDay;
              that.article_expDate.inbox = response.data.data.articleBExpInbox;
              that.article_expDate.pushnoti =
                response.data.data.articleBExpPushNoti;
              that.article_expDate.email = response.data.data.articleBExpEmail;

              that.soundcarddisable = response.data.data.soundFlag;
              that.sound_endDate.beforeEnd = response.data.data.soundBEndFlag;
              that.sound_endDate.beforeEndDate =
                response.data.data.soundBEndDay;
              that.sound_endDate.inbox = response.data.data.soundBEndInbox;
              that.sound_endDate.pushnoti =
                response.data.data.soundBEndPushNoti;
              that.sound_endDate.email = response.data.data.soundBEndEmail;

              that.sound_expDate.beforeExp = response.data.data.soundBExpFlag;
              that.sound_expDate.beforeExpDate =
                response.data.data.soundBExpDay;
              that.sound_expDate.inbox = response.data.data.soundBExpInbox;
              that.sound_expDate.pushnoti =
                response.data.data.soundBExpPushNoti;
              that.sound_expDate.email = response.data.data.soundBExpEmail;

              that.videocarddisable = response.data.data.videoFlag;
              that.video_endDate.beforeEnd = response.data.data.videoBEndFlag;
              that.video_endDate.beforeEndDate =
                response.data.data.videoBEndDay;
              that.video_endDate.inbox = response.data.data.videoBEndInbox;
              that.video_endDate.pushnoti =
                response.data.data.videoBEndPushNoti;
              that.video_endDate.email = response.data.data.videoBEndEmail;

              that.video_expDate.beforeExp = response.data.data.videoBExpFlag;
              that.video_expDate.beforeExpDate =
                response.data.data.videoBExpDay;
              that.video_expDate.inbox = response.data.data.videoBExpInbox;
              that.video_expDate.pushnoti =
                response.data.data.videoBExpPushNoti;
              that.video_expDate.email = response.data.data.videoBExpEmail;

              that.llcarddisable = response.data.data.lessonLearnFlag;
              that.lessonlearn_endDate.beforeEnd =
                response.data.data.lessonLearnBEndFlag;
              that.lessonlearn_endDate.beforeEndDate =
                response.data.data.lessonLearnBEndDay;
              that.lessonlearn_endDate.inbox =
                response.data.data.lessonLearnBEndInbox;
              that.lessonlearn_endDate.pushnoti =
                response.data.data.lessonLearnBEndPushNoti;
              that.lessonlearn_endDate.email =
                response.data.data.lessonLearnBEndEmail;

              that.lessonlearn_expDate.beforeExp =
                response.data.data.lessonLearnBExpFlag;
              that.lessonlearn_expDate.beforeExpDate =
                response.data.data.lessonLearnBExpDay;
              that.lessonlearn_expDate.inbox =
                response.data.data.lessonLearnBExpInbox;
              that.lessonlearn_expDate.pushnoti =
                response.data.data.lessonLearnBExpPushNoti;
              that.lessonlearn_expDate.email =
                response.data.data.lessonLearnBExpEmail;

              that.accarddisable = response.data.data.assignmentCourseFlag;
              that.AC_endDate.beforeEnd =
                response.data.data.assignmentCourseBEndFlag;
              that.AC_endDate.beforeEndDate =
                response.data.data.assignmentCourseBEndDay;
              that.AC_endDate.inbox =
                response.data.data.assignmentCourseBEndInbox;
              that.AC_endDate.pushnoti =
                response.data.data.assignmentCourseBEndPushNoti;
              that.AC_endDate.email =
                response.data.data.assignmentCourseBEndEmail;

              that.eventcarddisable = response.data.data.eventFlag;
              that.event_endDate.beforeEnd = response.data.data.eventBStartFlag;
              that.event_endDate.beforeEndDate =
                response.data.data.eventBStartDay;
              that.event_endDate.inbox = response.data.data.eventBStartInbox;
              that.event_endDate.pushnoti =
                response.data.data.eventBStartPushNoti;
              that.event_endDate.email = response.data.data.eventBStartEmail;
              that.CreateDate = response.data.data.createDate;
              that.CreateBy = response.data.data.createBy;
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async CreateOrUpdateSettingNotification() {
      let that = this;
      that.loading = true;
      let request = {
        CompanyID: localStorage.getItem("companyID"),
        emailLanguage: that.selectedUserRadio,
        ArticleFlag: that.carddisable,
        ArticleBEndFlag: that.article_endDate.beforeEnd,
        ArticleBEndDay: parseInt(that.article_endDate.beforeEndDate),
        ArticleBEndInbox: that.article_endDate.inbox,
        ArticleBEndPushNoti: that.article_endDate.pushnoti,
        ArticleBEndEmail: that.article_endDate.email,
        ArticleBExpFlag: that.article_expDate.beforeExp,
        ArticleBExpDay: parseInt(that.article_expDate.beforeExpDate),
        ArticleBExpInbox: that.article_expDate.inbox,
        ArticleBExpPushNoti: that.article_expDate.pushnoti,
        ArticleBExpEmail: that.article_expDate.email,
        SoundFlag: that.soundcarddisable,
        SoundBEndFlag: that.sound_endDate.beforeEnd,
        SoundBEndDay: parseInt(that.sound_endDate.beforeEndDate),
        SoundBEndInbox: that.sound_endDate.inbox,
        SoundBEndPushNoti: that.sound_endDate.pushnoti,
        SoundBEndEmail: that.sound_endDate.email,
        SoundBExpFlag: that.sound_expDate.beforeExp,
        SoundBExpDay: parseInt(that.sound_expDate.beforeExpDate),
        SoundBExpInbox: that.sound_expDate.inbox,
        SoundBExpPushNoti: that.sound_expDate.pushnoti,
        SoundBExpEmail: that.sound_expDate.email,
        VideoFlag: that.videocarddisable,
        VideoBEndFlag: that.video_endDate.beforeEnd,
        VideoBEndDay: parseInt(that.video_endDate.beforeEndDate),
        VideoBEndInbox: that.video_endDate.inbox,
        VideoBEndPushNoti: that.video_endDate.pushnoti,
        VideoBEndEmail: that.video_endDate.email,
        VideoBExpFlag: that.video_expDate.beforeExp,
        VideoBExpDay: parseInt(that.video_expDate.beforeExpDate),
        VideoBExpInbox: that.video_expDate.inbox,
        VideoBExpPushNoti: that.video_expDate.pushnoti,
        VideoBExpEmail: that.video_expDate.email,
        LessonLearnFlag: that.llcarddisable,
        LessonLearnBEndFlag: that.lessonlearn_endDate.beforeEnd,
        LessonLearnBEndDay: parseInt(that.lessonlearn_endDate.beforeEndDate),
        LessonLearnBEndInbox: that.lessonlearn_endDate.inbox,
        LessonLearnBEndPushNoti: that.lessonlearn_endDate.pushnoti,
        LessonLearnBEndEmail: that.lessonlearn_endDate.email,
        LessonLearnBExpFlag: that.lessonlearn_expDate.beforeExp,
        LessonLearnBExpDay: parseInt(that.lessonlearn_expDate.beforeExpDate),
        LessonLearnBExpInbox: that.lessonlearn_expDate.inbox,
        LessonLearnBExpPushNoti: that.lessonlearn_expDate.pushnoti,
        LessonLearnBExpEmail: that.lessonlearn_expDate.email,
        AssignmentCourseFlag: that.accarddisable,
        AssignmentCourseBEndFlag: that.AC_endDate.beforeEnd,
        AssignmentCourseBEndDay: parseInt(that.AC_endDate.beforeEndDate),
        AssignmentCourseBEndInbox: that.AC_endDate.inbox,
        AssignmentCourseBEndPushNoti: that.AC_endDate.pushnoti,
        AssignmentCourseBEndEmail: that.AC_endDate.email,
        EventFlag: that.eventcarddisable,
        EventBStartFlag: that.event_endDate.beforeEnd,
        EventBStartDay: parseInt(that.event_endDate.beforeEndDate),
        EventBStartInbox: that.event_endDate.inbox,
        EventBStartPushNoti: that.event_endDate.pushnoti,
        EventBStartEmail: that.event_endDate.email,
      };
      console.log("datamail",request);
      await axios
        .post(
          `${that.web_url}SendNotification/CreateOrUpdateSettingNotification`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.loading = false;
            that.Cancel();
          }
        })
        .catch(function (err) {
          alert(err);
        });
      that.loading = false;
    },
  },
};
</script>

<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}

.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}

.title_style {
  color: #000000;
  font-size: 22px;
}
.subtitle {
  color: #47484b;
  font-size: 20px;
}
.text-style {
  color: #47484b;
  font-size: 18px;
}

::v-deep .chk-input .v-input__control {
  align-content: end;
}
::v-deep .chk-input .v-input__slot {
  width: 200px;
}
::v-deep .input-field .v-input__slot {
  width: 127px !important;
  margin-top: 12px;
  left: 60% !important;
}
.font-style {
  font-size: 16px;
  color: #000000;
}

::v-deep .btnStyle .v-btn__content {
  font-size: 20px;
  color: #fff;
}
.chk-class {
  margin-left: 30px;
}
.div-class {
  margin-left: 8rem;
  margin-top: 10px;
}
::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #707070;
  background: white;
}
::v-deep
  .theme--light.v-text-field--outlined:not(
    .v-input--is-focused
  ).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #bbbbbb33;
  background: #bbbbbb33;
}
</style>

<style>
.v-card {
  border-radius: 18px !important;
  padding-bottom: 20px;
}
.noti-icon .v-icon {
  color: #424242 !important;
}
.v-application p {
  margin-bottom: 0px !important;
}
.divide {
  border-bottom: 1px solid #c0c0c0;
}
.v-input--switch--inset .v-input--selection-controls__input,
.v-input--switch--inset .v-input--switch__track {
  width: 70px !important;
  height: 31px !important;
}
.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #6fdc42;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
</style>